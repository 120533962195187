
import {defineComponent} from 'vue';
import {ASCENDING, DESCENDING, UNSORTED} from '@/constants/sort';

export default defineComponent({
  name: 'TableHeaderDialogSort',
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  emits: [
    'change'
  ],
  setup(props, {emit}) {
    const onChange = (value: SortDirection) => {
      if (value === UNSORTED) {
        emit('change', undefined);
        return;
      }
      emit('change', value);
    };

    const onClear = () => {
      emit('change');
    };

    return {
      onChange,
      onClear,
      UNSORTED,
      ASCENDING,
      DESCENDING,
    };
  },
});
