
import {computed, defineComponent, PropType, ref, watch} from 'vue';
import Button from '@/components/button/Button.vue';
import TableHeaderDialogFilter from '@/components/table/TableHeaderDialogFilter.vue';
import TableHeaderDialogSort from '@/components/table/TableHeaderDialogSort.vue';
import variables from '@/styles/variables.scss';
import {plainClone} from '@/utils/object';
import {FILTER_OP_NOT_SET} from '@/constants/filter';
import {ClickOutside} from 'element-plus/lib/directives';

export default defineComponent({
  name: 'TableHeaderFilter',
  components: {
    TableHeaderDialogSort,
    TableHeaderDialogFilter,
    Button,
  },
  directives: {
    ClickOutside,
  },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
    column: {
      type: Object as PropType<TableColumn>,
      required: true,
    },
    actionStatusMap: {
      type: Object,
      required: true,
    },
    sort: {
      type: Object as PropType<SortData>,
      required: false,
    },
    filter: {
      type: Object,
      required: false,
    },
  },
  emits: [
    'click',
    'cancel',
    'clear',
    'apply',
  ],
  setup(props, {emit}) {
    const defaultInternalSort = {key: props.column.key} as SortData;
    const internalSort = ref<SortData>();
    const internalFilter = ref<TableHeaderDialogFilterData>();

    const searchString = computed<string | undefined>(() => internalFilter.value?.searchString);

    const conditions = computed<FilterConditionData[]>(() => internalFilter.value?.conditions || []);

    const items = computed<string[]>(() => internalFilter.value?.items || []);

    const trueConditions = computed<FilterConditionData[]>(() => {
      return conditions.value?.filter(d => d.op !== FILTER_OP_NOT_SET);
    });

    const isEmptyFilter = computed<boolean>(() => {
      return !searchString.value && trueConditions.value.length == 0 && items.value.length === 0;
    });

    const isApplyDisabled = computed<boolean>(() => {
      for (const cond of trueConditions.value) {
        if (!cond.value) {
          return true;
        }
      }
      return false;
    });

    const cancel = () => {
      emit('cancel');
    };

    const clear = () => {
      if (!internalSort.value) internalSort.value = plainClone(defaultInternalSort);
      internalSort.value.d = undefined;
      internalFilter.value = undefined;
      emit('clear');
    };

    const apply = () => {
      if (!internalSort.value && isEmptyFilter.value) {
        clear();
        return;
      }
      const value: TableHeaderDialogValue = {
        sort: internalSort.value,
        filter: internalFilter.value,
      };
      emit('apply', value);
    };

    const onClickOutside = () => {
      const {visible} = props;
      if (!visible) return;
      cancel();
    };

    const onCancel = () => {
      cancel();
    };

    const onClear = () => {
      clear();
    };

    const onApply = () => {
      apply();
    };

    const onSortChange = (value: string) => {
      if (!internalSort.value) internalSort.value = plainClone(defaultInternalSort);
      internalSort.value.d = value;
    };

    const onFilterChange = (value: TableHeaderDialogFilterData) => {
      internalFilter.value = value;
    };

    const onFilterEnter = () => {
      apply();
    };

    watch(() => {
      const {visible} = props as TableHeaderDialogProps;
      return visible;
    }, () => {
      const {sort, filter, visible} = props as TableHeaderDialogProps;
      if (visible) {
        internalSort.value = (sort ? plainClone(sort) : plainClone(defaultInternalSort)) as SortData;
        internalFilter.value = plainClone(filter) as TableHeaderDialogFilterData;
      }
    });

    return {
      variables,
      internalSort,
      searchString,
      conditions,
      isApplyDisabled,
      onClickOutside,
      onCancel,
      onClear,
      onApply,
      onSortChange,
      onFilterChange,
      onFilterEnter,
    };
  },
});
