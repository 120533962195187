
import {defineComponent, PropType} from 'vue';
import FaIconButton from '@/components/button/FaIconButton.vue';
import {ACTION_ADD, ACTION_DELETE, ACTION_EDIT,} from '@/constants/action';
import {TABLE_ACTION_CUSTOMIZE_COLUMNS, TABLE_ACTION_EXPORT,} from '@/constants/table';

export default defineComponent({
  name: 'TableActions',
  components: {
    FaIconButton,
  },
  emits: [
    'edit',
    'delete',
    'export',
    'customize-columns'
  ],
  props: {
    selection: {
      type: Array as PropType<TableData>,
      required: false,
      default: () => {
        return [];
      },
    },
    visibleButtons: {
      type: Array as PropType<BuiltInTableActionButtonName[]>,
      required: false,
      default: () => {
        return [];
      }
    }
  },
  setup(props: TableActionsProps, {emit}) {
    // const onAdd = () => {
    //   emit('click-add');
    // };

    const onEdit = () => {
      emit('edit');
    };

    const onDelete = async () => {
      emit('delete');
    };

    const onExport = () => {
      emit('export');
    };

    const onCustomizeColumns = () => {
      emit('customize-columns');
    };

    const showButton = (name: string): boolean => {
      const {visibleButtons} = props;
      if (visibleButtons.length === 0) return true;
      return visibleButtons.includes(name);
    };

    return {
      ACTION_ADD,
      ACTION_EDIT,
      ACTION_DELETE,
      TABLE_ACTION_EXPORT,
      TABLE_ACTION_CUSTOMIZE_COLUMNS,
      // onAdd,
      onEdit,
      onDelete,
      onExport,
      onCustomizeColumns,
      showButton,
    };
  },
});
