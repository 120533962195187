
import {computed, defineComponent, PropType, ref, watch} from 'vue';
import Empty from '@/components/empty/Empty.vue';
import {getDefaultFilterCondition} from '@/components/filter/FilterCondition.vue';
// import FilterConditionList from '@/components/filter/FilterConditionList.vue';
import {debounce} from '@/utils/debounce';

export default defineComponent({
  name: 'TableHeaderDialogFilter',
  components: {
    // FilterConditionList,
    Empty,
  },
  props: {
    column: {
      type: Object as PropType<TableColumn>,
      required: false,
    },
    searchString: {
      type: String,
      required: false,
    },
    conditions: {
      type: Array as PropType<FilterConditionData[]>,
      required: false,
      default: () => {
        return [];
      }
    },
  },
  emits: [
    'change',
    'enter',
  ],
  setup(props, {emit}) {
    const internalConditions = ref<FilterConditionData[]>([getDefaultFilterCondition()]);
    const internalSearchString = ref<string>();
    const internalItems = ref<string[]>([]);

    const filterData = computed<TableHeaderDialogFilterData>(() => {
      return {
        searchString: internalSearchString.value,
        conditions: internalConditions.value,
        items: internalItems.value,
      };
    });

    const filteredItems = computed<SelectOption[]>(() => {
      const {column} = props as TableHeaderDialogFilterProps;

      const items = column?.filterItems;

      // undefined items
      if (!items) {
        // console.log('undefined items');
        return [];
      }

      // invalid type of items or empty items
      if (!Array.isArray(items) || items.length === 0) {
        // console.log('invalid type of items or empty items');
        return [];
      }

      // items as an array of SelectOption
      // console.log('items as an array of SelectOption');
      return items.filter(d => filterData.value.searchString ? d.label?.toLowerCase()?.includes(filterData.value.searchString) : true);
    });

    const onAddCondition = () => {
      internalConditions.value.push(getDefaultFilterCondition());
    };

    const onConditionsChange = (newConditions: FilterConditionData[]) => {
      internalConditions.value = newConditions;
      emit('change', filterData.value);
    };

    const onItemsChange = (newItems: string[]) => {
      internalItems.value = newItems;
      emit('change', filterData.value);
    };

    const search = debounce(() => {
      if (internalSearchString.value) {
        internalItems.value = filteredItems.value.map(d => d.value);
      } else {
        internalItems.value = [];
      }
      emit('change', filterData.value);
    }, {delay: 100});

    const onSearch = (value?: string) => {
      internalSearchString.value = value;
      search();
    };

    const onEnter = () => {
      emit('enter');
    };

    watch(() => {
      const {searchString} = props as TableHeaderDialogFilterProps;
      return searchString;
    }, (value) => {
      internalSearchString.value = value;
    });

    watch(() => {
      const {conditions} = props as TableHeaderDialogFilterProps;
      return conditions;
    }, (value) => {
      if (value) {
        internalConditions.value = value;
        if (internalConditions.value.length === 0) {
          internalConditions.value.push(getDefaultFilterCondition());
        }
      }
    });

    return {
      internalSearchString,
      internalConditions,
      internalItems,
      filteredItems,
      onAddCondition,
      onConditionsChange,
      onItemsChange,
      onSearch,
      onEnter,
    };
  },
});
