
import {defineComponent, inject, PropType, ref, SetupContext} from 'vue';
import {Table} from 'element-plus/lib/el-table/src/table.type';
import TableCell from '@/components/table/TableCell.vue';
import TableHeader from '@/components/table/TableHeader.vue';
import TableColumnsTransfer from '@/components/table/TableColumnsTransfer.vue';
import useColumn from '@/components/table/column';
import useHeader from '@/components/table/header';
import useData from '@/components/table/data';
import TableActions from '@/components/table/TableActions.vue';
import useAction from '@/components/table/action';
import usePagination from '@/components/table/pagination';

export default defineComponent({
  name: 'Table',
  components: {
    TableActions,
    TableColumnsTransfer,
    TableCell,
    TableHeader,
  },
  props: {
    data: {
      type: Array as PropType<TableData>,
      required: true,
      default: () => {
        return [];
      },
    },
    columns: {
      type: Array as PropType<TableColumn[]>,
      required: true,
      default: () => {
        return [];
      },
    },
    selectedColumnKeys: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => {
        return [];
      },
    },
    total: {
      type: Number,
      default: 0,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    rowKey: {
      type: String,
      default: '_id',
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    visibleButtons: {
      type: Array as PropType<BuiltInTableActionButtonName[]>,
      required: false,
      default: () => {
        return [];
      }
    },
    hideFooter: {
      type: Boolean,
      default: false,
    },
    selectableFunction: {
      type: Function as PropType<TableSelectableFunction>,
      default: () => true,
    }
  },
  emits: [
    'edit',
    'delete',
    'export',
    'header-change',
    'pagination-change',
    'selection-change',
  ],
  setup(props: TableProps, ctx: SetupContext) {
    const tableWrapperRef = ref();
    const tableRef = ref<Table>();

    const {
      tableData,
    } = useData(props, ctx);

    const {
      internalSelectedColumnKeys,
      columnsTransferVisible,
      selectedColumns,
      onShowColumnsTransfer,
      onHideColumnsTransfer,
      onColumnsChange,
    } = useColumn(props, ctx, tableRef, tableWrapperRef);

    const {
      onHeaderChange,
    } = useHeader(props, ctx);

    // inject action functions
    const actionFunctions = inject<ListLayoutActionFunctions>('action-functions');

    const {
      selection: internalSelection,
      onSelectionChange,
      onAdd,
      onEdit,
      onDelete,
      onExport,
    } = useAction(props, ctx, tableRef, actionFunctions as ListLayoutActionFunctions);

    const {
      onCurrentChange,
      onSizeChange,
    } = usePagination(props, ctx);

    return {
      tableWrapperRef,
      tableRef,
      tableData,
      internalSelectedColumnKeys,
      columnsTransferVisible,
      selectedColumns,
      onHeaderChange,
      onShowColumnsTransfer,
      onHideColumnsTransfer,
      onColumnsChange,
      onExport,
      internalSelection,
      onSelectionChange,
      onAdd,
      onEdit,
      onDelete,
      onCurrentChange,
      onSizeChange,
    };
  },
});
