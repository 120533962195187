
import {computed, defineComponent, ref, watch} from 'vue';
import {DataItem, Key} from 'element-plus/lib/el-transfer/src/transfer';
import Empty from '@/components/empty/Empty.vue';
import DraggableList from '@/components/drag/DraggableList.vue';

export default defineComponent({
  name: 'TransferPanel',
  components: {
    DraggableList,
    Empty,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Title',
    },
    checked: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    data: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    }
  },
  emits: [
    'check',
    'drag',
  ],
  setup(props, {emit}) {
    const searchString = ref<string>('');

    const isCheckedAll = ref<boolean>(false);

    const isIntermediate = ref<boolean>(false);

    const items = computed<DataItem[]>(() => {
      const {data} = props as TransferPanelProps;
      if (!searchString.value) {
        return data;
      }
      return data.filter(d => d.label.toLowerCase().includes(searchString.value.toLowerCase()));
    });

    const summary = computed<string>(() => {
      const {checked, data} = props as TransferPanelProps;
      return `${checked.length}/${data.length}`;
    });

    const onCheck = (value: Key[]) => {
      emit('check', value);
    };

    const onCheckAll = (value: boolean) => {
      const {data} = props as TransferPanelProps;
      emit('check', value ? data.map(d => d.key) : []);
    };

    const onDragEnd = (items: DataItem[]) => {
      emit('drag', items);
    };

    watch(() => {
      const {checked} = props as TransferPanelProps;
      return checked;
    }, () => {
      const {checked, data} = props as TransferPanelProps;
      isCheckedAll.value = checked.length > 0 && checked.length === data.length;
      isIntermediate.value = checked.length > 0 && checked.length < data.length;
    });

    return {
      searchString,
      isCheckedAll,
      isIntermediate,
      items,
      summary,
      onCheck,
      onCheckAll,
      onDragEnd,
    };
  },
});
